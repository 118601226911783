module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-images","options":{"backgroundColor":"#fafafa","maxWidth":1035}}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://equimper.com"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-88632749-2","anonymize":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EQuimper | Programming Blog","short_name":"EQuimper","start_url":"/","background_color":"#2779bd","theme_color":"#2779bd","display":"standalone","icons":[{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
